export const labels = {
    PICK_MEDIA:'pickMedia',
    POST_OR_DATE:'postOrDate',
    POST_SEARCH:'postSearch',
    POST_VIEW:'postView',
    ADVERTS:'adverts',
    STORY_ACTIVE_OR_MANUAL:'activeOrManual',
    STORY_SEARCH:'storySearch',
    STORY_VIEW:'storyView',
    STORY_MANUAL_ENTRY:'manualEntryStories',
    PUBLISH_DATES:'publishDates'

}

export const instagramMediaTypes = {
    POST:'post',
    STORY:'story',
}