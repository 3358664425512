<template>
    <div                
        class="flex-container flex-col pl24 pr24 scrollable-content pb24 postordate"
    >
        <p>
            post già  pubblicato ?
        </p>
        <InflooButton
            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
            @click="$emit('go-search-post')"
        >
            scegli tra i disponibili
        </InflooButton>
        
            <hr>
            <p>altrimenti</p>
            <InflooButton
                :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
                @click="$emit('go-publish-dates')"
            >
                inserisci la data di pubblicazione
            </InflooButton>
       
        
    </div>
</template>
<script>
import InflooButton from '@/components/buttons/InflooButton'
export default {
    props:{
        showDatesButton:{
            type:Boolean,
            default:true
        }
    },
    emits:['go-search-post', 'go-publish-dates'],
    components:{
        InflooButton
    }
}
</script>
<style lang="postcss" scoped>
.postordate button{
    line-height: 16px;
    font-size: 12px;
}
</style>