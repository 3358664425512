<template>
    <div                
        class="flex-container flex-col pl24 pr24 scrollable-content pb24"
    >
        <p>
           Vuoi associare una story attualmente in pubblicazione ?
        </p>
        <InflooButton
            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
            @click="$emit('go-story-search')"
        >
            scegli story
        </InflooButton>
        <hr>
        <p>Se la story è già scaduta puoi </p>
        <InflooButton
            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
            @click="$emit('go-story-manual-entry')"
        >
            aggiungere i dati manualmente
        </InflooButton>
    </div>
</template>
<script>
import InflooButton from '@/components/buttons/InflooButton'
export default {
    emits:['go-story-search', 'go-story-manual-entry'],
    components:{
        InflooButton
    }
}
</script>