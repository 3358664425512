<template>
     <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel pb24">
                <form 
                    class="flex-container flex-col min-height-inherit" 
                    id="add-post-panel" 
                    @submit.prevent="onSubmit"
                >
                    <div 
                        class="flex-container flex-col no-grow"
                    >
                        <div 
                            class="panel-header p24"
                        >
                            <div 
                                class="mini-logo"
                            ></div>
                            
                            <h2 
                                class="panel-title nomargin bebas"
                            >
                                Associa Nuovo Post 
                                <i class="lni lni-facebook-original"></i>
                            </h2>
                        </div>
                        <div 
                            v-if="data.step===1"
                            class="flex-container pl24 pr24 no-grow"
                        >
                            <div
                                class="flex-container flex-col"
                            >
                                <TextInput
                                    :label="'a partire dal'"
                                    :value="data.since 
                                        ? timeStampToFormattedDate(data.since) 
                                        : 'seleziona data'"
                                    :editing="false"
                                    :placeholder="'a partire dal'"
                                    :name="'since'"
                                    :className="'flex-container flex-col mb8 pr8'"
                                    @click="showDayTimes = true"
                                ></TextInput>
                            </div>
                             <div
                                class="flex-container flex-col"
                            >
                                <TextInput
                                    :label="'fino al'"
                                    :value="data.until 
                                        ? timeStampToFormattedDate(data.until) 
                                        : 'seleziona data'"
                                    :editing="false"
                                    :placeholder="'fino al '"
                                    :name="'until'"
                                    :className="'flex-container flex-col mb8 pl8'"
                                    @click="showDayTimes = true"
                                ></TextInput>
                            </div>
                            
                           
                        </div>
                     </div>
                     
                    <h2 
                        class="panel-title nomargin bebas pl24 pr24 mt8 mb8"
                    >
                        {{social.name}}
                    </h2>    
                    <div 
                        v-if="data.step===0" 
                        class="flex-container flex-col pl24 pr24 scrollable-content pb24"
                    >
                        <p>
                            post già  pubblicato ?
                        </p>
                        <InflooButton
                            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
                            @click="goToSearch"
                        >
                            scegli tra i disponibili
                        </InflooButton>
                        <hr>
                        <p>altrimenti</p>
                        <InflooButton
                            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
                            @click="data.step = 3"
                        >
                            inserisci la data di pubblicazione prevista
                        </InflooButton>
                    </div>
                    <div 
                        v-if="data.step===1" 
                        class="flex-container flex-col pl24 pr24 scrollable-content pb24"
                    >
                        <AddPostPanelCalendars
                            v-if="showDayTimes"
                            @dates-changed="onDatesChanged"
                            :startDate="data.since"
                            :endDate="data.until"
                        ></AddPostPanelCalendars>
                        
                        <ul 
                            v-else-if="data.posts.length > 0 "
                            class="nomargin nopadding flex-col flex-container layout-list"
                            >
                            <PostElement 
                                v-for="post in data.posts" 
                                :key="post.id" 
                                :post="post"
                                @list-select="onPostSelect"
                            ></PostElement>
                        </ul>
                        <div
                            v-else
                            class="flex-container flex-center-align flex-center-justify"
                        >
                           
                            <p v-if="isLoadingFacebookPosts">Carico i post...</p>
                            
                        </div>
                    </div>
                   
                    <div 
                        v-else-if="data.step===2" 
                        class="nomargin p24 flex-container flex-col flex-center-align scrollable-content"
                    >
                        <img 
                            :src="data.selectedPost.fullPicture" 
                            loading="lazy" 
                            class="w100"
                        />
                        <p 
                            class="layout-list-item nomargin mt24"
                        >
                            {{data.selectedPost.message}} 
                        </p>
                        <a 
                            :href="data.selectedPost.permalink" 
                            target="_blank" 
                            class="post-link bebas w100 pt16 pb16"
                        >
                            vedi post su facebook
                        </a>
                    </div>
                    <div 
                        v-else-if="data.step===3" 
                        class="nomargin p24 flex-container flex-col flex-center-align scrollable-content"
                    >
                        <p>Seleziona data ed ora di pubblicazione previste per il post</p>
                        <div class="calendar-container">
                            <VueCal
                                active-view="month"
                                class="vuecal--date-picker  vuecal--blue-theme"
                                xsmall
                                hide-view-selector
                                :time="false"
                                :transitions="false"
                                :disable-views="['weeks, year, days']"
                                :selected-date ="''"
                                :min-date="new Date()"
                                :style="{width: '208px', height: '240px'} "
                                :clickToNavigate="false"
                                @cell-focus="onPublishDateSelect"
                            >
                            </VueCal>
                        </div>
                        <div class="flex-container w100">
                            <select-input
                                :label="'Ora Giorno'"
                                :value="null"
                                :editing="true"
                                :placeholder="'seleziona ora'"
                                :options="dayhours"
                                :name="'fromHour'"
                                :className="'flex-container flex-col mb8 w100 pr8'"
                                @change="hourChange"
                            ></select-input>
                        </div>
                    </div>
                    <div class="flex-container panel-footer abs w100">
                        <div class="flex-container layout-list-footer">
                            <input 
                                type="reset" 
                                :disabled="isLoadingFacebookPosts" 
                                value="annulla" 
                                class="w100 noborder nopadding bebas" 
                                @click.prevent="onClose"
                            >
                            <input 
                                v-if="data.step===0 || data.step===1|| data.step===2 || data.step===3" 
                                type="submit" :value="nextButtonText" 
                                class="w100 noborder nopadding bebas" 
                                :disabled="data.step===0 || data.step===3 && (!data.publishDate || !data.publishHour)"
                            >
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </teleport>
</template>
<script>
import {computed, reactive, ref} from 'vue'
import {useStore} from 'vuex'
import PostElement from '@/components/campaigns/elements/PostElement'
import AddPostPanelCalendars from '@/components/campaigns/elements/AddPostPanelCalendars'
import TextInput from '@/components/formelements/TextInput'
import SelectInput from '@/components/formelements/SelectInput'
import {timeStampToFormattedDate, dayhours, strNumToNumber} from '@/utils/DateTimeUtils'
import InflooButton from '@/components/buttons/InflooButton'
import VueCal from 'vue-cal'
import {EventsHandler} from '@/utils/EventsHandler'
export default {
    emits:['close', 'add-success', 'oauth-error'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        },
        social:{
            type:Object,
            default:()=>{}
        },
        startStep:{
            type:Number,
            default:0
        },
        calendarId:{
            type:String,
            default:null
        },
        eventId:{
            type:String,
            default:null
        }
    },
    components:{
       TextInput,
        PostElement,
        AddPostPanelCalendars,
        InflooButton,
        VueCal,
        SelectInput
    },
    setup(props, {emit}){
       
        const store = useStore()
        const showDayTimes= ref(false)
        const data = reactive({
            step:props.startStep,
            selectedSocial:props.social,
            selectedPost:null,
            campaignId:props.campaign.id,
            posts:[],
            since:null,
            until:null,
            after:null,
            before:null,
            publishDate:null,
            publishHour:null,
            eventId:props.eventId
        })

        /**
         * Apre il pannello di ricerca post
         * 
         * @return Void
         */
        const goToSearch =()=>{
            data.step = 1
            searchSocial()
        }
        /**
         * Esegue la ricerca tra i post
         * 
         * @return Void
         */
        const searchSocial = async () => {
            noMorePosts.value = false
            isLoadingFacebookPosts.value= true
            const obj = {
                socialId: data.selectedSocial?.socialId,
                data:null
            }
            
            if(data.since || data.until){
                obj.data = {}
                obj.data.period = {
                    since:data.since.getTime ? data.since.getTime()/1000 : data.since/1000,
                    until:data.until.getTime ? data.until.getTime()/1000 : data.until/1000
                }
            }
            
            try {
                
                const response = await store.dispatch('post/facebookSearch',obj)
                if(response) {
                    
                    data.posts = response.posts
                    data.after = response.after
                    data.before = response.before
                } else {
                    
                    showDayTimes.value = false
                    data.posts = []
                    data.after = null
                    data.before = null
                    noMorePosts.value = true
                }
                isLoadingFacebookPosts.value= false
                
            } catch (error) {
                
                if(
                    error.type=== "OAuthException" 
                    || error.message.startsWith('Invalid OAuth access token.') 
                    || error.message.startsWith('(#200) Provide valid app ID') 
                    || error.message === '190' 
                    || parseInt(error.message) === 190
                    || error.message === '200' 
                    || parseInt(error.message) === 200
                    || parseInt(error.message) === 10
                    || parseInt(error.message) === 104
                ){
                    emit('close')
                    emit('oauth-error')
                } else {
                    await store.dispatch('messages/message', {
                        title:'errore durante il caricamento dei post ', 
                        message:'Invalid OAuth access token.' 
                    })
                }
                
            } 
        }
        
        const isLoadingFacebookPosts = ref(false)
        let noMorePosts = ref(false)
        /**
         * Cerca post aggiuntivi
         * 
         * @return Void
         */
        const loadMorePosts = async() => {
            
            if(!isLoadingFacebookPosts.value){
                const obj = {
                    socialId: data.selectedSocial.socialId,
                    data:{
                        after:data.after
                    }
                }
                if(data.since || data.until){
                    obj.data.period = {
                        since:data.since.getTime ? data.since.getTime()/1000 : data.since/1000,
                    until:data.until.getTime ? data.until.getTime()/1000 : data.until/1000
                    }
                }
                isLoadingFacebookPosts.value = true
                const response = await store.dispatch('post/facebookSearch',obj)
                if(response) {
                    if(response.posts){
                    data.posts = data.posts.concat(response.posts)
                    data.after = response.after
                    data.before = response.before
                    } else {
                        noMorePosts.value = true
                    }
                } else {
                    showDayTimes.value = false
                    data.posts = []
                    data.after = null
                    data.before = null
                    noMorePosts.value = true
                }

                
                isLoadingFacebookPosts.value = false
            } 
        }

        /**
         * Determina il testo del bottone "next"
         * 
         * @return String
         */
        const nextButtonText = computed(() => {

            if(data.step === 1 ){
                if(showDayTimes.value){
                    return 'seleziona date'
                } else{
                    if(isLoadingFacebookPosts.value){
                        return 'loading...'
                    }
                    if(noMorePosts.value){
                        return 'non ci sono altri post'
                    }
                    return 'carica altri post'
                }
            } else if(data.step === 3){
                return 'salva'
            } else {
                return 'associa'
            }
        })

        /**
         * Alla selezione di un post
         * ne visualizza foto e contenuto 
         * 
         * @return Void
         */
        const onPostSelect = (post)=>{
            data.step =2
            data.selectedPost = post
        }
        /**
         * Decide se abilitare o meno il bottone 'next'
         * che porta alla prossima schemata 
         * 
         * @return Boolean
         */
        const preventNext = computed(()=>{
            if(data.step === 1 
                && !data.selectedSocial )
            {
                return false
            }
            
            return true
        })
        /**
         * Esegiuta al chiudersi del pannello
         * 
         * @return Void
         */
        const onClose = () => {
            if (showDayTimes.value) {
                showDayTimes.value = false
            } else {
                emit('close')
            }
        }
        /**
         * Esegiuta al submit
         * a seconda dello step corrente
         * cerca post, carica post aggiuntivi,
         * salva i dati, salva la data di pubblicazione
         * prevista per un post
         * 
         * @return Void
         */
        const onSubmit = async () => {
            if (data.step === 1) {
                if(showDayTimes.value){
                    showDayTimes.value = false
                    await searchSocial()
                    
                } else {
                   await loadMorePosts()
                }
            }
            if (data.step === 2) {
                await save()
            } 
            if (data.step === 3) {
                await savePublishDate()
            }  
        }
        /**
         * Assegna un post ad una campagna
         * 
         * @return Void
         */
        const save = async()=>{
            try {
                
                const payload = {
                    socialId: data.selectedSocial.socialId,
                    campaignId: data.campaignId,
                    postId:data.selectedPost.postId,
                    eventId:props.eventId
                }

                const post = await store.dispatch('post/facebookAssignPost',payload)
                
                await store.dispatch('messages/message', {
                    title: 'post associato',
                    message:'il post ' +data.selectedPost.postId + ' è stato associato correttamente'
                })

                emit('close')
                emit('add-success', post) 
                
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:"errore durante l'associazione ", 
                    message:error.message 
                })
                emit('close')
            }
        }

        /**
         * Salva nel calendario una data di pubblicazione prevista
         * 
         * @return Void
         */
        const savePublishDate = async() =>{
            
            const startDate = new Date(data.publishDate)
            startDate.setHours(
                strNumToNumber(data.publishHour.split(':')[0]), 
                strNumToNumber(data.publishHour.split(':')[1])
            )
            
            const object = {
                summary: props.campaign.title + ' : pubblicazione post su ' + data.selectedSocial.name,
                start : new Date(startDate),
                end : new Date(startDate),
                sharedExtendedProperties:{
                    socialName : data.selectedSocial.name,
                    socialId : data.selectedSocial.socialId,
                    campaign:props.campaign.title,
                    campaignId:data.campaignId,
                    discriminator:data.selectedSocial.discriminator
                }

            }
            try {
                await store.dispatch('campaigns/publishDate', object)
                 await store.dispatch('messages/message', {
                        title: 'Data di pubblicazione',
                        message:'la data di pubblicazione del post è stata salvata correttamente'
                })
                emit('close')
                emit('add-success') 
                await store.dispatch('calendar/index')
                EventsHandler.publish('publish-date')
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore durante il salvataggio dei dati ', 
                    message:error.message 
                })
                emit('close')
            }
        }
        /**
         * Esegiuta alla selezione di una data di pubblicazione
         * 
         * @return Void
         */
        const onPublishDateSelect = (cell) =>{
            data.publishDate = cell
        }
        /**
         * Esegiuta al cambio di date selezionate
         * 
         * @return Void
         */
        const onDatesChanged = (dates) => {
            
            const start = dates.startDate.getTime ? dates.startDate.getTime() : dates.startDate
            const end = dates.endDate.getTime ? dates.endDate.getTime() : dates.endDate
            if(start === end){
                if(dates.startDate.getTime && dates.endDate.getTime){    
                    data.since = dates.startDate.setHours(0, 0)
                    data.until = dates.endDate.setHours(23, 59,59)
                } else {
                    data.since = new Date(start).setHours(0, 0)
                    data.until = new Date(end).setHours(23, 59,59)
                }
            }else{
                data.since = new Date(start).setHours(0, 0)
                data.until = new Date(end).setHours(23, 59,59)
            }
        }

        if(data.step ===1) searchSocial()

        /**
         * Esegiuta al cambio di orario nella ricerca
         * 
         * @return Void
         */
        const hourChange = (e)=>{
           data.publishHour = e.target.value
        }

        return{
            nextButtonText,
            isLoadingFacebookPosts,
            data,
            preventNext,
            onClose,
            onPostSelect,
            onSubmit,
            showDayTimes,
            noMorePosts,
            onDatesChanged,
            timeStampToFormattedDate,
            dayhours,
            hourChange,
            savePublishDate,
            onPublishDateSelect,
            goToSearch
        }
    }
    
}
</script>
<style lang="postcss" scoped>
#add-post-panel button{
    line-height: 16px;
    font-size: 12px;
}
#add-post-panel .add-social-list .layout-list {
    max-height: 100%;
    height: initial;
    overflow-y: auto;
}
.overlay-panel #add-post-panel  input[type="reset"]:focus {
  outline: none;
  background-color: #2cbeff;
  color: #F2F2F2;
}
#add-post-panel img {
    max-width: 100%;
}

.layout-list-footer{
        font-size: 22px;
    line-height: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
}
.scrollable-content{
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}
a.post-link {
    color: white;
    background-color: #2cbeff;
    text-align: center;
    padding: 1em 0;
    text-decoration: none;
}
.overlay-panel .flex-container.scrollable-content{
    flex:unset;
}
.calendar-container{
    height: 240px;
}
.calendar-container .vuecal .vuecal__cell--disabled  {
    pointer-events:none;
}

</style>