<template>
    <div 
        class="flex-container flex-col no-grow"
    >
        <div 
            class="panel-header p24"
        >
            <div 
                class="mini-logo"
            ></div>
            
            <h2 
                class="panel-title nomargin bebas"
            >
                Associa Nuovo Media 
                <i class="lni lni-instagram-original"></i>
            </h2>
        </div>
        <div 
            v-if="label=== labels.POST_SEARCH"
            class="flex-container pl24 pr24 no-grow"
        >
            <div
                class="flex-container flex-col"
            >
                <TextInput
                    :label="'a partire dal'"
                    :value="since 
                        ? timeStampToFormattedDate(since) 
                        : 'seleziona data'"
                    :editing="false"
                    :placeholder="'a partire dal'"
                    :name="'since'"
                    :className="'flex-container flex-col mb8 pr8'"
                    @click="$emit('click')"
                ></TextInput>
            </div>
            <div
                class="flex-container flex-col"
            >
                <TextInput
                    :label="'fino al'"
                    :value="until 
                        ? timeStampToFormattedDate(until) 
                        : 'seleziona data'"
                    :editing="false"
                    :placeholder="'fino al '"
                    :name="'until'"
                    :className="'flex-container flex-col mb8 pl8'"
                    @click="$emit('click')"
                ></TextInput>
            </div>
            
            
        </div>
    </div>
</template>
<script>
import TextInput from '@/components/formelements/TextInput'
import {timeStampToFormattedDate} from '@/utils/DateTimeUtils'
import {labels} from '@/utils/InstagramUtils'
export default {
    emits:['click'],
    components:{
        TextInput
    },
    props:{
        label:{
            type:[String, Number],
            default:0
        },
        since:{
            type:String,
            default:null
        },
        until:{
            type:String,
            default:null
        }
    },
    setup(){
        
        return{
            timeStampToFormattedDate,
            labels
        }
    }
}
</script>