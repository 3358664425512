<template>
    <h2 
        class="panel-title nomargin bebas pl24 pr24 mt8 mb8"
    >
        {{socialName}} 
        <span 
            v-if="step===4"
            class="pl8 f16"
        >
            post {{postId}}
        </span>
    </h2> 
</template>
<script>
export default {
    props:{
        postId:{
            type:String,
            default:''
        },
        socialName:{
            type:String,
            default:''
        },
        step:{
            type:Number,
            default:0
        },

    }
}
</script>