<template>
    <div                
        class="flex-container flex-col pl24 pr24 scrollable-content pb24 pickmedia"
    >
        <p>
           aggiungi post
        </p>
        <InflooButton
            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
            @click="$emit('go-search-post')"
        >
            scegli post
        </InflooButton>
        <hr>
        <p>aggiungi story</p>
        <InflooButton
            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
            @click="$emit('go-story-active-manual')"
        >
            scegli story
        </InflooButton>
        <hr>
        <p v-if="showDates">
        altrimenti</p>
        <InflooButton
            v-if="showDates"
            :className="'button primary pl8 pr8 flex-center-align flex-container no-grow flex-center-justify'"
            @click="$emit('go-publish-dates')"
        >
            inserisci la data di pubblicazione
        </InflooButton>
    </div>
</template>
<script>
import InflooButton from '@/components/buttons/InflooButton'
export default {
    emits:['go-post-or-date', 'go-story-active-manual', 'go-publish-dates','go-search-post'],
    components:{
        InflooButton
    },
    props:{
        showDates:{
            type:Boolean
        }
    }
}
</script>
<style lang="postcss" scoped>
.pickmedia button{
    line-height: 16px;
    font-size: 12px;
}
</style>