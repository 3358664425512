<template>
    <div 
        class="nomargin p24 flex-container flex-col flex-center-align scrollable-content"
    >
        <RetryingImageSocial 
            :url="mediaUrl" 
            :socialName="'instagram'"
            :class="'w100'"
            :fallbackImage="true"
        />
        <p 
            class="layout-list-item nomargin mt24"
        >
            {{caption}} 
        </p>
        <a 
            :href="permalink" 
            target="_blank" 
            class="post-link bebas w100 pt16 pb16"
        >
            vedi post su Instagram
        </a>
    </div>
</template>
<script>
import RetryingImageSocial from '@/components/RetryingImageSocial'
export default {
    components:{
        RetryingImageSocial
    },
    props:{
        mediaUrl:{
            type:String,
            default:null
        },
        caption:{
            type:String,
            default:''
        },
        permalink:{
            type:String,
            default:''
        }
    }
}
</script>