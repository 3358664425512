<template>
    <div               
        class="nomargin p24 flex-container flex-col  scrollable-content"
    >
        <p>
            Inserisci i dati della story
        </p>
        <select-input
            :label="'Media type'"
            :value="state.mediaType"
            :editing="true"
            :options="['Image', 'Video']"
            :name="'mediaType'"
            :className="'flex-container flex-col mb8 w100 pr8'"
            :required="true"
            @change="e => state.mediaType = e.target.value"
        ></select-input>
         <TextInput
            :label="'caption'"
            :value="state.caption"
            :editing="true"
            :placeholder="'inserisci caption'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.caption = val.text"
        ></TextInput>
        <TextInput
            :label="'copertura organica'"
            :value="state.organicCoverage"
            :editing="true"
            :placeholder="'inserisci copertura organica'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.organicCoverage = val.text"
        ></TextInput>
        <TextInput
            :label="'impressions'"
            :value="state.impressions"
            :editing="true"
            :placeholder="'inserisci impressions'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.impressions = val.text"
        ></TextInput>
        <TextInput
            :label="'replies'"
            :value="state.replies"
            :editing="true"
            :placeholder="'inserisci replies'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.replies = val.text"
        ></TextInput>
        <TextInput
            :label="'taps Back'"
            :value="state.tapsBack"
            :editing="true"
            :placeholder="'inserisci tapsBack'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.tapsBack = val.text"
        ></TextInput>
        <TextInput
            :label="'like count'"
            :value="state.tapsBack"
            :editing="true"
            :placeholder="'inserisci tapsBack'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.likeCount = val.text"
        ></TextInput>
        <TextInput
            :label="'Comments Count'"
            :value="state.commentsCount"
            :editing="true"
            :placeholder="'inserisci tapsBack'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.commentsCount = val.text"
        ></TextInput>
        <TextInput
            :label="'taps Forward'"
            :value="state.tapsForward"
            :editing="true"
            :placeholder="'inserisci replies'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.tapsForward = val.text"
        ></TextInput>
        <TextInput
            :label="'exits'"
            :value="state.exits"
            :editing="true"
            :placeholder="'inserisci exits'"
            :className = "'flex-container flex-col no-grow add-description pb16'"
            :required="true"
            @input="(val)=> state.exits = val.text"
        ></TextInput>
        
    </div>
</template>
<script>
import {reactive, watch} from 'vue'
import TextInput from '@/components/formelements/TextInput'
import SelectInput from '@/components/formelements/SelectInput'
export default {
    emits:['story-edit'],
    
    components:{
        TextInput,
        SelectInput
    },
    props:{
        mockedPost:{
            type:Object,
            default:null
        }
    },
    setup(props,{emit}){
       
        const state = reactive({
            postId:props.mockedPost?.postId || null,
            mediaType:props.mockedPost?.mediaType || null,
            caption: props.mockedPost?.caption || null,
            commentsCount: props.mockedPost?.commentsCount || null,
            likeCount: props.mockedPost?.likeCount || null,
            organicCoverage: props.mockedPost?.organicCoverage || null,
            impressions: props.mockedPost?.impressions || null,     
            exits: props.mockedPost?.exits || null,
            replies: props.mockedPost?.replies || null,
            tapsForward: props.mockedPost?.tapsForward || null,
            tapsBack: props.mockedPost?.tapsBack || null
        })
        
        watch(() => state,
           async (  state ) => {
               emit('story-edit', state)     
            },
            { deep: true }
        )

       
        return {
            state
        }
    }

}
</script>