<template>
    <div
        class="flex-container flex-col pl24 pr24 scrollable-content pb24"
    >
        <AddPostPanelCalendars
            v-if="showDayTimes"
            @dates-changed="(dates)=>$emit('dates-changed', dates)"
            :startDate="since"
            :endDate="until"
        ></AddPostPanelCalendars>
        <div
            v-else-if="loading"
            class="flex-container flex-center-align flex-center-justify"
        >
            <p>Carico i dati...</p>
        </div>
        <ul 
            v-if="medias.length > 0 "
            class="nomargin nopadding flex-col flex-container layout-list"
            >
            <InstagramPostSearchElement 
                v-for="post in medias" 
                :key="post.id" 
                :post="post"
                :externalLink="true"
                @list-select="(post)=> $emit('list-select', post)"
                
            ></InstagramPostSearchElement>
        </ul>
        <div
            v-if="nomorePosts"
            class="flex-container flex-center-align flex-center-justify"
        >
            <p>non ci sono dati da visualizzare</p>
        </div>
        
    </div>
</template>
<script>
import InstagramPostSearchElement from '@/components/campaigns/instagram/panels/elements/InstagramPostSearchElement'
import AddPostPanelCalendars from '@/components/campaigns/elements/AddPostPanelCalendars'

export default {
    components:{
        InstagramPostSearchElement,
        AddPostPanelCalendars
    },
    emits:['dates-changed', 'list-select'],
    props:{
        since:{
            type:[String, Date, Number],
            default:null
        },
        until:{
            type:[String, Date, Number],
            default:null
        },
        medias:{
            type:Array,
            default:()=>[]
        },
        showDayTimes:{
            type:Boolean,
            default:false
        },
        nomorePosts:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        },
    }


}
</script>
<style lang="postcss" >
#add-post-panel button{
    line-height: 16px;
    font-size: 12px;
}
.overlay-panel button{
    font-size: 16px;
}

</style>