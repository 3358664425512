<template>
    <div               
        class="nomargin p24 flex-container flex-col  scrollable-content"
    >
        <p>
            Ci sono Inserzioni da associare a questo post ? <br>
            inserisci gli <span class="bold">id</span> delle campagne da associare, separati da una virgola
        </p>
        <TextAreaInput
            :label="'Id Campagne'"
            :value="''"
            :editing="true"
            :placeholder="'inserisci gli id delle campagne'"
            ref="description"
            :className = "'flex-container flex-col add-description pb16'"
            :required="false"
            @input="(val)=> $emit('input', val)"
        ></TextAreaInput>
        
    </div>
</template>
<script>
import TextAreaInput from '@/components/formelements/TextAreaInput'
export default {
    emits:['input'],
    components:{
        TextAreaInput
    }

}
</script>