<template>
     <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel pb24">
                <form 
                    class="flex-container flex-col min-height-inherit" 
                    id="add-post-panel" 
                    @submit.prevent="onNextPress"
                >
                    <InstagramAddMediaHeader 
                        :label="data.label" 
                        :since="data.since" 
                        :until="data.until" 
                        @click="onShowDayTimes"
                    />
                    
                    <InstagramAddPostPanelTitle
                        :socialName="social.name"
                        :step="data.step"
                        :postId="data.selectedPost?.id || null"
                    ></InstagramAddPostPanelTitle> 

                    <InstagramPickMedia
                        v-if="data.label===labels.PICK_MEDIA"
                        :showDates="eventId === null"
                        @go-search-post="data.label = labels.POST_SEARCH"
                        @go-story-active-manual="data.label = labels.STORY_ACTIVE_OR_MANUAL"
                        @go-publish-dates="data.label = labels.PUBLISH_DATES"
                    />

                    <InstagramPostOrDate
                        v-if="data.label===labels.POST_OR_DATE"
                        :showDatesButton="calendarId !== null"
                        @go-search-post="data.label = labels.POST_SEARCH"
                        @go-publish-dates="data.label = labels.PUBLISH_DATES"
                    /> 

                    <InstagramPostSearch
                        v-if="data.label === labels.POST_SEARCH || data.label === labels.STORY_SEARCH" 
                        :showDayTimes="data.showDayTimes"
                        :medias="data.medias"
                        :since="data.since"
                        :until="data.until"
                        :loading="data.isLoadingPosts"
                        @list-select="onPostSelect"
                        @dates-changed="onDatesChanged"
                        :nomorePosts="data.noMorePosts"
                    />
                   
                    <InstagramPostView
                        v-if="data.label === labels.POST_VIEW"
                        :mediaUrl="data.selectedPost.mediaUrl"
                        :caption="data.selectedPost.caption"
                        :permalink="data.selectedPost.permalink"
                    />

                    <InstagramStoryActiveManual 
                        v-if="data.label===labels.STORY_ACTIVE_OR_MANUAL"
                        @go-story-search="data.label = labels.STORY_SEARCH"
                        @go-story-manual-entry="data.label = labels.STORY_MANUAL_ENTRY"
                    />

                    <InstagramStoryManualEntry
                        v-if="data.label===labels.STORY_MANUAL_ENTRY"
                        @story-edit="onManualStoryChanged"
                        :mockedPost="data.manualStoryData"
                    />
                   
                    <InstagramPublishDates
                        v-if="data.label === labels.PUBLISH_DATES"
                        @cell-focus="onPublishDateSelect"
                        @change="hourChange"
                    />

                    <InstagramAdverts
                        v-if="data.label === labels.ADVERTS"
                        @input="onCampaignIdsInput"
                    />                   
                    
                    <div class="flex-container panel-footer abs w100">
                        <div class="flex-container layout-list-footer">
                            <input 
                                type="reset" 
                                :disabled="isLoadingPosts" 
                                :value="data.backButtonText" 
                                class="w100 noborder nopadding bebas" 
                                @click.prevent="onBackPress"
                            >
                            <input 
                                 type="submit" :value="data.nextButtonText" 
                                class="w100 noborder nopadding bebas" 
                                :disabled="data.preventNext"
                            >
                        </div>
                    </div>
                </form>
            </div> 
        </div>
    </teleport>
</template>
<script>
import { reactive, /* ref, */ watch} from 'vue'
import {useStore} from 'vuex'
import InstagramAddMediaHeader from '@/components/campaigns/instagram/panels/elements/InstagramAddMediaHeader'
import InstagramAddPostPanelTitle from '@/components/campaigns/instagram/panels/elements/InstagramAddPostPanelTitle'
import InstagramPickMedia from '@/components/campaigns/instagram/panels/elements/InstagramPickMedia'
import InstagramPostOrDate from '@/components/campaigns/instagram/panels/elements/InstagramPostOrDate'
import InstagramPostSearch from '@/components/campaigns/instagram/panels/elements/InstagramPostSearch'
import InstagramPostView from '@/components/campaigns/instagram/panels/elements/InstagramPostView'
import InstagramPublishDates from '@/components/campaigns/instagram/panels/elements/InstagramPublishDates'
import InstagramAdverts from '@/components/campaigns/instagram/panels/elements/InstagramAdverts'
import InstagramStoryActiveManual from '@/components/campaigns/instagram/panels/elements/InstagramStoryActiveManual'
import InstagramStoryManualEntry from '@/components/campaigns/instagram/panels/elements/InstagramStoryManualEntry'
import {strNumToNumber} from '@/utils/DateTimeUtils'
import {EventsHandler} from '@/utils/EventsHandler'

import {labels, instagramMediaTypes} from '@/utils/InstagramUtils'

export default {
    emits:['close', 'add-success', 'oauth-error'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        },
        social:{
            type:Object,
            default:()=>{}
        },
        startStep:{
            type:Number,
            default:0
        },
        calendarId:{
            type:String,
            default:null
        },
        label:{
            type:String,
            default:labels.PICK_MEDIA
        },
        eventId:{
            type:String,
            default:null
        },
        selectedPost:{
            type:Object,
            default:null
        }
    },
    components:{
        InstagramAddMediaHeader,
        InstagramPickMedia,
        InstagramPostOrDate,
        InstagramPostSearch,
        InstagramPostView,
        InstagramPublishDates,
        InstagramAdverts,
        InstagramAddPostPanelTitle,
        InstagramStoryActiveManual,
        InstagramStoryManualEntry
    },
    setup(props, {emit}){
        
        const store = useStore()
        const data = reactive({
            step:props.startStep,
            label:props.label || labels.PICK_MEDIA,
            selectedSocial:props.social,
            selectedPost:props.selectedPost,
            campaignId:props.campaign.id,
            medias:[],
            since:null,
            until:null,
            after:null,
            before:null,
            publishDate:null,
            publishHour:null,
            eventId:props.eventId,
            advertismentIds: [],
            nextButtonText:'avanti',
            backButtonText:'annulla',
            preventNext:true,
            showDayTimes:false,
            noMorePosts:false,
            isLoadingPosts:false,
            manualStoryData:null
        })



        const onShowDayTimes = ()=> {
            data.showDayTimes = true 
            data.preventNext = false
            data.nextButtonText = 'seleziona date'
        }           
        

        watch(
           () => data.label,
           async (  label/* , prevlabel */) => {
                switch(label){
                   case labels.PICK_MEDIA:
                        data.backButtonText = 'annulla'
                        data.nextButtonText = 'avanti'
                        data.preventNext = true
                       break;
                   case labels.POST_OR_DATE:
                       data.preventNext = true
                       data.nextButtonText = 'avanti'
                       data.backButtonText = 'indietro'
                       break
                   case labels.POST_SEARCH:
                       data.noMorePosts = false
                       await searchSocial(instagramMediaTypes.POST)
                       data.preventNext = false
                       if(data.selectedPost){
                           data.nextButtonText = 'avanti'
                       } else {
                           data.nextButtonText = 'carica altri post'
                       }
                       data.backButtonText = 'indietro'
                       
                       break
                   case labels.POST_VIEW:
                       data.preventNext = false
                       data.nextButtonText = 'avanti'
                       data.backButtonText = 'indietro'
                       break
                   case labels.ADVERTS:
                       data.preventNext = false
                       data.nextButtonText = 'associa'
                       data.backButtonText = 'indietro'
                       break
                   case labels.STORY_ACTIVE_OR_MANUAL:
                       data.backButtonText = 'indietro'
                       break
                   case labels.STORY_SEARCH:
                       data.noMorePosts = false
                       await searchSocial(instagramMediaTypes.STORY)
                       data.preventNext = false
                       if(data.selectedPost){
                           data.nextButtonText = 'avanti'
                       } else {
                           data.nextButtonText = 'carica altre story'
                       }
                       data.backButtonText = 'indietro'
                       if(data.noMorePosts){
                           data.preventNext = true
                       }
                       break
                   case labels.STORY_VIEW:
                       data.preventNext = false
                       data.nextButtonText = 'avanti'
                       data.backButtonText = 'indietro'
                       break 
                   case labels.STORY_MANUAL_ENTRY:
                       data.preventNext = true
                       data.nextButtonText = 'avanti'
                       data.backButtonText = 'indietro'
                       //data.manualStoryData = null
                       break
                   case labels.PUBLISH_DATES:
                       break 

                }
            }
        )

       
        /**
         * Esegue la ricerca tra i post
         * 
         * @return Void
         */
        const searchSocial = async (type) => {
            
            data.noMorePosts = false
            data.isLoadingPosts = true
            const obj = {
                socialId: data.selectedSocial?.socialId,
                data:null
            }
            
            if(data.since || data.until){
                
                obj.data = {}
                obj.data.period = {
                    since:data.since.getTime ? data.since.getTime()/1000 : data.since/1000,
                    until:data.until.getTime ? data.until.getTime()/1000 : data.until/1000
                }
            }
            
            try {

                const url = type === instagramMediaTypes.POST ? 'post/instagramSearch' : 'post/instagramSearchStory'

                const response = await store.dispatch(url,obj)
                
                if(response){
                    data.medias = response.medias
                    data.after = response.after
                    data.before = response.before
                    if(data.medias.length === 0) {
                        data.noMorePosts = true
                    }
                } else {
                    data.noMorePosts = true
                    data.medias = []
                }
                data.isLoadingPosts = false

                
            } catch (error) {
                
                if(
                    error.message === "OAuthException" ||
                    error.type=== "OAuthException" 
                    || error.message.startsWith('Invalid OAuth access token.') 
                    || error.message.startsWith('(#200) Provide valid app ID') 
                    || error.message.startsWith('The user must be an administrator, editor, or moderator') 
                    || error.message === '190' 
                    || parseInt(error.message) === 190
                    || error.message === '200' 
                    || error.message === '100' 
                    || parseInt(error.message) === 200
                    || parseInt(error.message) === 10
                    || parseInt(error.message) === 104
                ){
                    emit('close')
                    emit('oauth-error')
                } else {
                    
                    emit('close')
                    await store.dispatch('messages/message', {
                        title:'errore durante il caricamento dei post ', 
                        message:'Server error.' 
                    })
                }
                
            } 
        }
        
        /**
         * Cerca post aggiuntivi
         * 
         * @return Void
         */
        const loadMorePosts = async(type) => {
            if(!data.isLoadingPosts){
                const obj = {
                    socialId: data.selectedSocial.socialId,
                    data:{
                        after:data.after
                    }
                }
                if(data.since || data.until){
                    obj.data.period = {
                        since:data.since.getTime ? data.since.getTime()/1000 : data.since/1000,
                    until:data.until.getTime ? data.until.getTime()/1000 : data.until/1000
                    }
                }
                data.isLoadingPosts = true
                data.nextButtonText = '...loading posts'
                const url = type === instagramMediaTypes.POST ? 'post/instagramSearch' : 'post/instagramSearchStory'

                const response = await store.dispatch(url,obj)
                if(response.medias){
                    data.medias = data.medias.concat(response.medias)
                    data.after = response.after
                    data.before = response.before
                    data.nextButtonText = 'carica altri post'
                } else {
                    data.noMorePosts = true
                    data.nextButtonText = 'non ci sono più post'
                }
                data.isLoadingPosts = false
            } 
        }

       /**
         * Alla selezione di un post
         * ne visualizza foto e contenuto 
         * 
         * @return Void
         */
        const onPostSelect = (post)=>{
            data.selectedPost = post
            data.label = labels.POST_VIEW
            
        }
        
        /**
         * Esegiuta al chiudersi del pannello
         * 
         * @return Void
         */
        const onBackPress = () => {
            switch(data.label){
                case labels.PICK_MEDIA:
                    emit('close')
                    break
                case labels.POST_OR_DATE:
                    data.label = labels.PICK_MEDIA
                    break
                case labels.POST_SEARCH:
                    data.label = labels.PICK_MEDIA
                    if (data.showDayTimes) {
                        data.showDayTimes = false
                    }
                    if(data.selectedPost){
                        data.selectedPost = null
                    }
                    data.since = null
                    data.until = null
                    break
                case labels.POST_VIEW:
                    data.label = labels.POST_SEARCH
                    if (data.showDayTimes) {
                        data.showDayTimes = false
                    }
                    if(data.selectedPost){
                        data.selectedPost = null
                    }
                    data.medias = []
                    break
                case labels.ADVERTS:
                    if(data.selectedPost){
                        if(data.selectedPost.mediaProductType === 'STORY'){
                            data.label = labels.STORY_VIEW
                        } else {
                            data.label = labels.POST_VIEW
                        }
                    } else {
                        data.label = labels.STORY_MANUAL_ENTRY
                    }

                    
                                      
                    break
                case labels.STORY_ACTIVE_OR_MANUAL:
                    data.label = labels.PICK_MEDIA
                    break
                case labels.STORY_SEARCH:
                    data.label = labels.PICK_MEDIA
                    if (data.showDayTimes) {
                        data.showDayTimes = false
                    }
                    if(data.selectedPost){
                        data.selectedPost = null
                    }
                    data.since = null
                    data.until = null
                    break
                case labels.STORY_VIEW:
                    data.label = labels.STORY_SEARCH
                    if (data.showDayTimes) {
                        data.showDayTimes = false
                    }
                    if(data.selectedPost){
                        data.selectedPost = null
                    }
                    data.medias = []
                    break 
                case labels.STORY_MANUAL_ENTRY:
                    data.label = labels.STORY_ACTIVE_OR_MANUAL
                    data.manualStoryData = null
                    data.preventNext = true
                    break
                case labels.PUBLISH_DATES:
                   data.label = labels.PICK_MEDIA
                    break 

            }  
        }
        /**
         * Esegiuta al submit
         * a seconda dello step corrente
         * cerca post, carica post aggiuntivi,
         * salva i dati, salva la data di pubblicazione
         * prevista per un post
         * 
         * @return Void
         */
        const onNextPress = async () => {

            switch(data.label){
                case labels.POST_SEARCH:
                    if(data.showDayTimes){
                        data.showDayTimes = false
                        await searchSocial(instagramMediaTypes.POST)
                        
                    } else {
                        await loadMorePosts(instagramMediaTypes.POST)
                    }
                    break
                case labels.POST_VIEW:
                    data.label = labels.ADVERTS
                    break
                case labels.ADVERTS:
                    if(data.manualStoryData){
                        await saveStory()
                    } else {
                        await save()
                    }
                    
                    break
                case labels.STORY_ACTIVE_OR_MANUAL:
                    break
                case labels.STORY_SEARCH:
                    if(!data.selectedPost){
                        await loadMorePosts(instagramMediaTypes.SORY)
                    } 
                    break
                case labels.STORY_VIEW:
                    break 
                case labels.STORY_MANUAL_ENTRY:
                    data.label = labels.ADVERTS
                    break
                case labels.PUBLISH_DATES:
                    await savePublishDate()
                    break 

            }  
        }
        /**
         * Assegna un post ad una campagna
         * 
         * @return Void
         */
        const save = async()=>{
            try {
                
                const payload = {
                    socialId: data.selectedSocial.socialId,
                    campaignId: data.campaignId,
                    postId:data.selectedPost.id,
                    eventId:props.eventId
                }
                
                const post = await store.dispatch('post/instagramAssignPost',payload)
                const adsLength = data.advertismentIds.length
                if(adsLength > 0){
                    for (let index = 0; index < adsLength; index++) {
                         
                        const adId =  data.advertismentIds[index] 
                       
                        const adPayload = {
                            socialId: data.selectedSocial.socialId,
                            campaignId: data.campaignId,
                            postId:data.selectedPost.id,
                            advertisementId:adId
                        }

                        // chiamata x sincare gli adv : POST - /api/advertisement/instagram
                        await store.dispatch('post/addInstagramAdvertismentToPost',adPayload)
                    }
                   
                } 

                await store.dispatch('messages/message', {
                    title: 'post associato',
                    message:'il post ' +data.selectedPost.id + ' è stato associato correttamente'
                })

                emit('close')
                emit('add-success', post) 
                
            } catch (error) {
                console.log(error)

                if(
                    error.message === "OAuthException" 
                ){
                    emit('close')
                    emit('oauth-error')
                } else {
                    await store.dispatch('messages/message', {
                        title:"errore durante l'associazione ", 
                        message:error.message 
                    })
                    emit('close')
                }
                
            }
        }

        const saveStory = async () => {
            
            try {
                
                const payload = {
                    socialId: data.selectedSocial.socialId,
                    campaignId: data.campaignId,
                    mediaType: data.manualStoryData.mediaType,
                    caption: data.manualStoryData.caption,
                    commentsCount: data.manualStoryData.commentsCount,
                    likeCount: data.manualStoryData.likeCount,
                    organicCoverage: data.manualStoryData.organicCoverage,
                    impressions: data.manualStoryData.impressions,
                    reach: data.manualStoryData.reach,
                    exits: data.manualStoryData.exits,
                    replies: data.manualStoryData.replies,
                    tapsForward: data.manualStoryData.tapsForward,
                    tapsBack: data.manualStoryData.tapsBack
                }
                
                const post = await store.dispatch('post/instagramManualAssignStory',payload)
                const adsLength = data.advertismentIds.length
                if(adsLength > 0){
                    for (let index = 0; index < adsLength; index++) {
                         
                        const adId =  data.advertismentIds[index] 
                       
                        const adPayload = {
                            socialId: data.selectedSocial.socialId,
                            campaignId: data.campaignId,
                            postId:post.postId,
                            advertisementId:adId
                        }

                        // chiamata x sincare gli adv : POST - /api/advertisement/instagram
                        await store.dispatch('post/addInstagramAdvertismentToPost',adPayload)
                    }
                   
                } 

                await store.dispatch('messages/message', {
                    title: 'post associato',
                    message:'la story ' + post.postId + ' è stata associata correttamente'
                })

                emit('close')
                emit('add-success', post) 
                
            } catch (error) {
                console.log(error)

                if(
                    error.message === "OAuthException" 
                ){
                    emit('close')
                    emit('oauth-error')
                } else {
                    await store.dispatch('messages/message', {
                        title:"errore durante l'associazione ", 
                        message:error.message 
                    })
                    emit('close')
                }
                
            }
        }

        /**
         * Salva nel calendario una data di pubblicazione prevista
         * 
         * @return Void
         */
        const savePublishDate = async() =>{
            
            const startDate = new Date(data.publishDate)
            startDate.setHours(
                strNumToNumber(data.publishHour.split(':')[0]), 
                strNumToNumber(data.publishHour.split(':')[1])
            )
            
            const object = {
                summary: props.campaign.title + ' : pubblicazione post su ' + data.selectedSocial.name,
                start : new Date(startDate),
                end : new Date(startDate),
                sharedExtendedProperties:{
                    socialName : data.selectedSocial.name,
                    socialId : data.selectedSocial.socialId,
                    campaign:props.campaign.title,
                    campaignId:data.campaignId,
                    discriminator:data.selectedSocial.discriminator
                    
                }

            }
            try {
                await store.dispatch('campaigns/publishDate', object)
                await store.dispatch('messages/message', {
                        title: 'Data di pubblicazione',
                        message:'la data di pubblicazione del post è stata salvata correttamente'
                })
                emit('close')
                emit('add-success') 
                await store.dispatch('calendar/index')
                EventsHandler.publish('publish-date')
            } catch (error) {
                await store.dispatch('messages/message', {
                    title:'errore durante il salvataggio dei dati ', 
                    message:error.message 
                })
                emit('close')
            }
        }
        /**
         * Esegiuta alla selezione di una data di pubblicazione
         * 
         * @return Void
         */
        const onPublishDateSelect = (cell) =>{
            data.publishDate = cell
        }
        /**
         * Esegiuta al cambio di date selezionate
         * 
         * @return Void
         */
        const onDatesChanged = ( dates ) => {
            
            const start = dates.startDate.getTime ? dates.startDate.getTime() : dates.startDate
            const end = dates.endDate.getTime ? dates.endDate.getTime() : dates.endDate
            if(start === end){
                if(dates.startDate.getTime && dates.endDate.getTime){    
                    data.since = dates.startDate.setHours(0, 0)
                    data.until = dates.endDate.setHours(23, 59,59)
                } else {
                    data.since = new Date(start).setHours(0, 0)
                    data.until = new Date(end).setHours(23, 59,59)
                }
            }else{
                data.since = new Date(start).setHours(0, 0)
                data.until = new Date(end).setHours(23, 59,59)
            }
        }

        if(data.label === labels.POST_SEARCH) searchSocial(instagramMediaTypes.POST)

        /**
         * Esegiuta al cambio di orario nella ricerca
         * 
         * @return Void
         */
        const hourChange = ( e ) => {
           data.publishHour = e.target.value
           if(data.publishHour && data.publishDate){
               data.preventNext = false
           }
        }

        const onCampaignIdsInput = (e) => {
            const {text} = e
            if(text || text === ''){
                data.advertismentIds = text.split(',').map(t => t.trim())
            }
        }


        const onManualStoryChanged = (storyData) => {
            data.manualStoryData = storyData
            let allEdit = true
            
            for (const key in storyData) {
                if(key !=='postId' && (storyData[key] === null || storyData[key] === '')){
                    allEdit = false
                }
            }
           
            
            if(allEdit) {
                data.nextButtonText = 'avanti'
                data.preventNext = false
            }
        } 
        return{
            labels,
            data,
            onBackPress,
            onPostSelect,
            onNextPress,
            onDatesChanged,
            hourChange,
            savePublishDate,
            onPublishDateSelect,
            onCampaignIdsInput,
            onShowDayTimes,
            onManualStoryChanged
        }
    }
    
}
</script>
<style lang="postcss" scoped>
#add-post-panel button{
    line-height: 16px;
    font-size: 12px;
}
#add-post-panel .add-social-list .layout-list {
    max-height: 100%;
    height: initial;
    overflow-y: auto;
}
.overlay-panel #add-post-panel  input[type="reset"]:focus {
  outline: none;
  background-color: black;
  color: #F2F2F2;
}
#add-post-panel img {
    max-width: 100%;
}

.layout-list-footer{
        font-size: 22px;
    line-height: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;
}
.scrollable-content{
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
}
a.post-link {
    color: white;
    background-color: #2cbeff;
    text-align: center;
    padding: 1em 0;
    text-decoration: none;
}
.overlay-panel .flex-container.scrollable-content{
    flex:unset;
}
.calendar-container{
    height: 240px;
}
.calendar-container .vuecal .vuecal__cell--disabled  {
    pointer-events:none;
}

</style>