<template>
    <div class="flex-container flex-col">
        <div  
            class=" calendars"
        >
            <linked-calendars
                :startDate="dates.startDate"
                :endDate="dates.endDate"
                :editing="true"
                @data-change="onDatesChanged"
            ></linked-calendars>
        </div>
        <div class="flex-container">
            <select-input
                :label="'Ora Giorno'"
                :value="null"
                :editing="true"
                :placeholder="'seleziona ora'"
                :options="dayhours"
                :name="'fromHour'"
                :className="'flex-container flex-col mb8 w100 pr8'"
                @change="hourChange"
            ></select-input>
            <select-input
                :label="'Ora Giorno'"
                :value="null"
                :editing="true"
                :placeholder="'seleziona ora'"
                :options="dayhours"
                :name="'toHour'"
                :className="'flex-container flex-col mb8 w100 pl8'"
                @change="hourChange"
            ></select-input>
        </div>
        
        
    </div>
    
</template>
<script>
import LinkedCalendars from '@/components/LinkedCalendars'
import { reactive } from 'vue'

import SelectInput from '@/components/formelements/SelectInput'

export default {
    emits:['dates-changed'],
    components:{
        LinkedCalendars,
        SelectInput
    },
    props:{
        startDate:{
            type:[String, Date, Number],
            default:null
        },
        endDate:{
            type:[String, Date, Number],
            default:null
        },
    },
    setup(props, {emit}){
        const dates = reactive({
            startDate: props.startDate ? props.startDate  : new Date().setHours(0, 0),
            endDate:props.endDate ? props.endDate  : new Date().setHours(23, 59, 59),
        })

        const onDatesChanged = (newDates) =>{
            dates.startDate = newDates.startDate
            dates.endDate= newDates.endDate
            emit('dates-changed', newDates)
        }

        const dayhours = [
            '00:00',
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00'
        ]
        const hourChange = (e)=>{
            if(e.target.id === 'fromHour'){
                const startDate = new Date(dates.startDate)
                startDate.setHours(parseInt(e.target.value.split(':')[0]), 0)
                dates.startDate = new Date(startDate)
                dates.endDate= new Date(dates.endDate)

            }
            if(e.target.id === 'toHour'){
                const endDate = new Date(dates.endDate)
                endDate.setHours(parseInt(e.target.value.split(':')[0]), 0)
                dates.startDate = new Date(dates.startDate)
                dates.endDate= new Date(endDate)
            }
            
            emit('dates-changed', dates)
        } 
        return{
            onDatesChanged,
            dates,
            dayhours,
            hourChange
        }
    }
}
</script>
<style lang="postcss" >
.calendars {
    background-color: white;
    top: 80px;
    
    z-index: 2;
}
#sincehour, #untilhour{
    padding-left: 8px;
}
</style>