<template>
    <li class="layout-list-item">
        <router-link 
            :to="tab ? '/campaigns/'+ campaign.id + '?tab='+ tab :'/campaigns/'+ campaign.id" 
            :class="selected ? 'layout-list-item-btn flex-container  p8 pl16 pr16 selected ' : 'layout-list-item-btn flex-container p8 pl16 pr16 '"
        >
            <i v-if="campaign.type === 'Facebook'" class="lni lni-facebook-original"></i>
            <i v-else-if="campaign.type === 'Instagram'" class="lni lni-instagram"></i>
            <div class="flex-container flex-col flex-grow-1 campaignlist-text-container">
                <div class="flex-container flex-space-between-justyfy">
                    <div class="layout-list-item-title nomargin pl8">{{campaign.title}}</div>
                    <i v-if="campaign?.advertiserUser?.userName" class="f12">{{campaign.advertiserUser.userName}}</i>
                </div>
                <div v-if="campaign?.campaignTags" class="f12 pl8 notextwrap">{{campaign.campaignTags.map(t =>t.tag.name).join(', ')}}</div>
            </div>
            

            
        </router-link>
    </li>
</template>
<script>
import {computed} from 'vue'
import {useRoute} from 'vue-router'
export default {
    emits:['list-select'],
    props:{
        campaign:{
            type:Object,
            default:()=>{}
        },
        selectedId:{
            type:String,
            default:''
        }
    },
    setup(props,context){
        const selected = computed(()=>{
            return parseInt(props.campaign.id) === parseInt(props.selectedId )
        }) 
        const onSelect=()=>{
            context.emit('list-select', props.campaign)
        }
        const route = useRoute()

        const tab = computed(() => {
            if(!route.query?.tab){
                return false
            }
            if(route.query?.tab === 'campaign' || route.query?.tab === 'analytics'){
                return route.query.tab
            } else {
                const t = props.campaign.type === 'Facebook' ? 'facebook' : 'instagram'
                return t
            }
        })
        return{
            onSelect,
            selected,
            route,
            tab
        }
    }
    
}
</script>
<style scoped>
.campaignlist-text-container{
    max-width: 97%;
}
.notextwrap{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>