<template>
    <div                 
        class="nomargin p24 flex-container flex-col flex-center-align scrollable-content"
    >
        <p>Seleziona data ed ora di pubblicazione previste per il post</p>
        <div class="calendar-container">
            <VueCal
                active-view="month"
                class="vuecal--date-picker  vuecal--blue-theme"
                xsmall
                hide-view-selector
                :time="false"
                :transitions="false"
                :disable-views="['weeks, year, days']"
                :selected-date ="''"
                :min-date="new Date()"
                :style="{width: '208px', height: '240px'} "
                :clickToNavigate="false"
                @cell-focus="(cell) => $emit('cell-focus', cell)"
            >
            </VueCal>
        </div>
        <div class="flex-container w100">
            <select-input
                :label="'Ora Giorno'"
                :value="null"
                :editing="true"
                :placeholder="'seleziona ora'"
                :options="dayhours"
                :name="'fromHour'"
                :className="'flex-container flex-col mb8 w100 pr8'"
                @change="e => $emit('change', e)"
            ></select-input>
        </div>
    </div>
</template>
<script>
import VueCal from 'vue-cal'
import SelectInput from '@/components/formelements/SelectInput'
import {dayhours} from '@/utils/DateTimeUtils'
export default {
    emits:['cell-focus', 'change'],
    components:{
        VueCal,
        SelectInput
    },
    setup(){
        return{
            dayhours
        }
    }
    
}
</script>