<template>
    <div :class="className" id="linked-dates">
        <div :class="layout === 'horizontal' ?'flex-container flex-col pr8 mb8' : 'flex-container flex-col mb8'">
           <div class="flex-container heading-and-error no-grow">
                <label>
                    <h3 class="bebas nomargin">Inizio</h3>
                </label>    
            </div>
            <VueCal
                active-view="month"
                class="vuecal--date-picker  vuecal--blue-theme"
                xsmall
                hide-view-selector
                :time="false"
                :transitions="false"
                :disable-views="['week']"
                :selected-date ="data.startDate || ''"
                :style="{width: '100%', height: '250px'} "
                :clickToNavigate="false"
                ref='startCal'
                @cell-focus="onStartDate"
                :key="'start-'+keysuffix"
            >
            </VueCal>
            
        </div>
        <div :class="layout === 'horizontal' ?'flex-container flex-col pl8 mb8' : 'flex-container flex-col mb8'">
           <div class="flex-container heading-and-error no-grow">
                <label>
                    <h3 class="bebas nomargin">Fine</h3>  
                </label>  
            </div>
            <VueCal
               
                active-view="month"
                class="vuecal--date-picker  vuecal--blue-theme"
                xsmall
                hide-view-selector
                :time="false"
                :transitions="false"
                :disable-views="['week']"
                :selected-date ="data.endDate || ''"
                :clickToNavigate="false"
                :style="{width: '100%', height: '250px'} "
                @cell-focus="onEndDate"
                :key="'end-'+keysuffix"
            >
            </VueCal>
            
        </div>                    
    </div>
</template>
<script>
import VueCal from 'vue-cal'
import {reactive, computed} from 'vue'
import {timeStampToFormattedDate} from '@/utils/DateTimeUtils'
export default {
   emits:['data-change'], 
   components:{
       VueCal
   },
   props:{
        editing:{
            type:Boolean,
            default:true
        },
        keysuffix:{
            type:Number,
            default:0
        },
        startDate:{
            type:[String, Date, Number],
            default:null
        },
        endDate:{
            type:[String, Date, Number],
            default:null
        },
        layout:{
            type:String,
            default:'horizontal'
        }
   },
   setup(props, {emit}){

        const className = computed(()=>{
            let cls = 'flex-container linked-dates no-grow'
            if(!props.editing){
                cls += ' disabled'
            }
            if(props.layout !== 'horizontal'){
                cls += ' flex-col'
            }
            return cls
        })

         let startTimezoneOffset = new Date(props.startDate).getTimezoneOffset()
        let endTimezoneOffset = new Date(props.endDate).getTimezoneOffset()

        const newStart = new Date(props.startDate).setHours(0 -(startTimezoneOffset/60),0,0)



        const newEnd = new Date(props.endDate).setHours(21- (endTimezoneOffset/60),59,59)


       

        const data = reactive({
            startDate:new Date(newStart),
            endDate: new Date(newEnd),
        })

       
        
        const onStartDate = (cell)=>{
            
            data.startDate = cell
            if(!data.endDate || cell.getTime() > data.endDate.getTime()){
                 data.endDate = cell
            }
            emit('data-change', data)
        }
        const onEndDate = (cell)=>{
            
            data.endDate = cell
            if(!data.startDate || cell.getTime() < data.startDate.getTime()){
                data.startDate = cell
            }
            emit('data-change', data)
        }
        return{
           data,
           onStartDate,
           onEndDate,
           timeStampToFormattedDate,
           className
        }
   } 
}
</script>
<style lang="postcss">
.linked-dates .vuecal--no-time .vuecal__bg {
    overflow: hidden;
}
.linked-dates.disabled .vuecal__cell {
    pointer-events: none;
}

</style>